import Select from "react-select";

import selectStyles from "../common/select-styles";
import LoadingField from "../loading-field/LoadingField";

export const propStates = [
    {value: 1, label: "Nuevo"},
    {value: 2, label: "Usado"},
    {value: 3, label: "Proyecto"},
    {value: 4, label: "En Construcción"}
];

/**
 * @param {{
 *  propState: {value, label} | null
 *  setPropState: Function,
 *  loading: boolean
 * }} props
 */
export default function PropertyStateSelect(props) {
    const onChangeHandler = choice => props.setPropState(choice ? choice : null);

    if(props.loading) {
        return(<LoadingField />)
    }

    return (
        <Select
            className={"basic-single"}
            classNamePrefix="select"
            isSearchable={false}
            isClearable={true}
            name="Estado propiedad"
            options={propStates}
            placeholder="Estado propiedad"
            styles={selectStyles()}
            noOptionsMessage={() => "No hay opciones"}
            onChange={onChangeHandler}
            defaultValue={props.propState}
        />
    )
}