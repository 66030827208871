import { RETagManager } from "../../utils/gtm-utils";
import ContactForm from "../contact-form/ContactForm";
import Css from "./ContactInformation.module.css";

/**
 * @param {{hideTitle: boolean}} props
 */
export default function ContactInformation(props) {
    return (
        <div>
            <div>
                <h2 className="mt-0">Datos de contacto</h2>
            </div>
            <div className={Css.contactInfoDiv}>
                <p>Teléfonos</p>
                <a href="tel:+573228547342"
                    onClick={() => RETagManager.sendCallAtemptEvent()}>
                    +57 322 854 7342
                </a>
                <a 
                    className={Css.phone}
                    href='tel:+573143507094'
                    onClick={() => RETagManager.sendCallAtemptEvent()}
                >
                    +57 314 3507094
                </a>
            </div>
            <div className={Css.contactInfoDiv}>
                <p>WhatsApp</p>
                <a href="https://wa.me/573228547342"
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={() => RETagManager.sendWhatsAppAttemptEvent()}>
                    +57 322 854 7342
                </a>
                <a className={Css.phone} href='https://wa.me/573143507094
' 
                    target='_blank' 
                    rel='noopener noreferer'
                    onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                >
                        +57 314 3507094
                </a>
            </div>
            <div className={Css.contactInfoDiv}>
                <p>Correo</p>
                <a
                    href="mailto:marialeonor@restrepoecheverri.com"
                    onClick={() => RETagManager.sendEmailAtemptEvent()}
                >marialeonor@restrepoecheverri.com</a>
            </div>
            <div className={Css.contactInfoDiv}>
                <p>Ubicación</p>
                <p>Avenida Alberto Mendoza</p>
                <p>Cra 23 A No 74-71</p> 
                <p>Oficina 504</p>
                <p>Edificio Andi</p>
                <p>Manizales</p>
                {/* <p>Oficina 504</p>
                <a href="https://goo.gl/maps/ADKTTLk7pv41b3De6" target="_blank" rel="noopener noreferrer">
                    Edificio Andi<br/>Cra 23A # 74 - 71
                </a> */}
            </div>
            <div className="mt-4">
                <h2>Síganos en Redes Sociales</h2>
            </div>
            <div className={Css.contactInfoDiv + " col-12 col-sm-6"}>
                <p>Instagram</p>
                <a 
                    href="https://www.instagram.com/inmobiliariarestrepoecheverri/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={() => RETagManager.sendInstagramLinkClickEvent()}
                >
                    @inmobiliariarestrepoecheverri
                </a>
            </div>
            <div className={Css.contactInfoDiv + " col-12 col-sm-6"}>
                <p>Facebook</p>
                <a href="https://www.facebook.com/inmobiliariarestrepoecheverri"
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={() => RETagManager.sendFacebookClickEvent()}
                >
                    /inmobiliariarestrepoecheverri
                </a>
            </div>
        </div>
        // <div className="container">
        //     {props.hideTitle ? "" : (
        //         <h2 className="text-center text-size-4">Contáctenos</h2>
        //     )}
        //     <div className="row">
        //         {/*className="d-flex flex-column flex-lg-row-reverse"*/}
        //         <div className="col-12 col-md-7 col-lg-8 col-xl-9">
        //             <div className={Css.contactWrapper}>
        //                 <div className={Css.contactDiv}>
        //                     <ContactForm title="Formulario de contacto" />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
)
}