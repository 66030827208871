import { addQueryParams2Url } from "../utils/url-utils";

/**
 * @param {string} url url to fetch
 * @param {Object.<string, any>} cache cache object
 * @param {null | {Object.<string, any>}} query_params query_parameters
 * to include in the URL as GET parameters. See addQueryParams2Url for
 * more information.
 * @returns {Promise<any>}
 */
export default async function cacheFetch(url, cache = {}, query_params = null) {
    if(query_params) {
        url = addQueryParams2Url(url, query_params);
    }

    if(url in cache) {
        console.log("cached " + url);
        return cache[url];
    }

    const response = await fetch(url);
    if(response.status !== 200) {
        throw new Error("Unable to fetch " + url +
            ". API responded with status " + response.status);
    }

    const data = await response.json();
    cache[url] = data;

    return data;
}