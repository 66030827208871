import Select from "react-select";
import Css from "./OrderBySelect.module.css";

export const OrderByOptions = [
    {value: {order_by: "created_at", order: "desc"}, label: "Más reciente"},
    {value: {order_by: "created_at", order: "asc"}, label: "Menos reciente"},
    {value: {order_by: "max_price", order: "desc"}, label: "Mayor precio"},
    {value: {order_by: "min_price", order: "desc"}, label: "Menor precio"},
];

export const OrderByDefaultOption = OrderByOptions[0];

/**
 * 
 * @param {{
 *  setOrderBy: Function()
 * }} props 
 * @returns 
 */
export default function OrderBySelect(props) {
    const style = {
        control: (bs) => {
            return {...bs, borderRadius: "10px"};            
        }
    };

    const classNames = {singleValue: () => Css.orderByMsg};

    const onChangeHandler = (choice) => {
        if(props.setOrderBy) {
            props.setOrderBy(choice)
        }
    }

    return (
        <Select
            className={"basic-single"}
            classNamePrefix="select"
            isSearchable={false}
            name="Ordernar por"
            options={OrderByOptions}
            classNames={classNames}
            placeholder="Ordernar por"
            styles={style}
            onChange={onChangeHandler}
            value={props.orderBy}
            defaultValue={OrderByDefaultOption}
        />
    )
}