import Css from "./PropertyDetail.module.css";

export default function LoadingPropertyDetail() {
    const loadingClass = " " + Css.loading + " animated-background";
    window.scrollTo(0,0);
    return (
        <div className={Css.wrapper}>
            <div className="container">
                <div className={"row " + Css.titlePriceDiv}>
                    <div className="col-12 col-lg-8">
                        <h1 className={Css.title + loadingClass}></h1>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className={Css.priceLabel + loadingClass}></div>
                        <div className={Css.price + loadingClass}></div>
                    </div>
                </div>
                <div className={Css.swiperWrapper + loadingClass}></div>
                <div className={Css.summaryWrapper + loadingClass}></div>
            </div>
        </div>
    )
}