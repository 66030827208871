import { BUSINESS_TYPE_RENT, BUSINESS_TYPE_SALE } from "../components/property-search/business-type-select/BusinessTypeSelect";

/**
 * @param {Object} response API response data
 * @param {string} valueKey key that should be mapped to value
 * @param {string} labelKey key the shoul be mapped to label
 * @param {boolean} removeEmpty should options with quantity = 0 be removed?
 * 
 * @returns {{
 *  value: any,
 *  label: string
 * }[]}
 */
export function apiResponse2Options(response, valueKey, labelKey, removeEmpty = true) {
    let options = [];
    for(let key in response) {
        if(key !== "status") {
            if((response[key]["quantity"] !== 0) || (removeEmpty === false)) {
                options.push({
                    value: response[key][valueKey],
                    label: response[key][labelKey]
                });
            }
        }
    }

    return options;
}

/**
 * 
 * @param {{
 *      propType: {value, label} | null,
 *      businessType: {value, label} | null,
 *      city: {value, label} | null,
 *      location: {value, label} | null,
 *      zone: {value, label} | null,
 *      propState: {value, label} | null,
 *      rooms: {value, label} | null,
 *      bathrooms: {value, label} | null,
 *      minPrice: {value, label} | null,
 *      maxPrice: {value, label} | null,
 * }} filters 
 */
export function filters2ApiFilters(filters) {
    const apiFilters = {};
    const keyMap = {
        "propType":         "id_property_type",
        "city":             "id_city",
        "location":         "id_location",
        "zone":             "id_zone",
        "propState":        "id_property_condition",
        "rooms":            "min_bedrooms",
        "bathrooms":        "bathrooms",
        "minPrice":         "min_price",
        "maxPrice":         "max_price"
    };

    for(let key in keyMap) {
        if(filters[key]) {
            const apiKey = keyMap[key];
            apiFilters[apiKey] = filters[key].value;
        }
    }

    if(filters.businessType) {
        if(filters.businessType.value === BUSINESS_TYPE_RENT) {
            apiFilters.for_rent = true;
        } else if(filters.businessType.value === BUSINESS_TYPE_SALE) {
            apiFilters.for_sale = true;
        }
    }

    return apiFilters;
}