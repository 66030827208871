import { PROPERTY_STRATUM_LABELS } from "../../../services/property-service";
import { getAreaUnitElement } from "../../../utils/area-utils";
import Css from "./PropDetailSummary.module.css";

/**
 * @param {{property: {
 *  area: string
 *  built_area: string
 *  private_area: string
 *  unit_area_label: string
 *  unit_built_area_label: string
 *  unit_private_area_label: string
 *  bedrooms: string
 *  bathrooms: string,
 *  stratum: string
 * }}} props 
 **/
export default function PropDetailSummary(props) {
    const prop = props.property;
    let numAreas = 0;
    let numDetails = 0;
    if(prop.area) numAreas += 1;
    if(prop.built_area) numAreas += 1;
    if(prop.private_area) numAreas += 1;
    if(prop.bathrooms) numDetails += 1;
    if(prop.bedrooms) numDetails += 1;
    if(prop.stratum) {
        numDetails += 1;
        prop.stratum_label = PROPERTY_STRATUM_LABELS[prop.stratum];
    }

    console.log(prop);

    if(numAreas === 0 && numDetails === 0) {
        return;
    }

    return (
        <div className={Css.wrapper + " shadow-sm"}>
            <div className="row">
                {prop.area ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.area} {getAreaUnitElement(prop.unit_area_label)}
                        </div>
                        <div className={Css.detailLabel}>
                            Lote
                        </div>
                    </div>
                ) : ""}
                {prop.built_area ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.built_area} {getAreaUnitElement(prop.unit_built_area_label)}
                        </div>
                        <div className={Css.detailLabel}>
                            Construidos
                        </div>
                    </div>
                ) : ""}
                {prop.private_area ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.private_area} {getAreaUnitElement(prop.unit_private_area_label)}
                        </div>
                        <div className={Css.detailLabel}>
                            Privados
                        </div>
                    </div>
                ) : ""}
                {prop.bedrooms ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.bedrooms}
                        </div>
                        <div className={Css.detailLabel}>
                            Habitaciones
                        </div>
                    </div>
                ) : ""}
                {prop.bathrooms ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.bathrooms}
                        </div>
                        <div className={Css.detailLabel}>
                            Baños
                        </div>
                    </div>
                ) : ""}
                {prop.stratum_label ? (
                    <div className="col">
                        <div className={Css.detailInfo}>
                            {prop.stratum_label}
                        </div>
                        <div className={Css.detailLabel}>
                            Estrato
                        </div>
                    </div>
                ) : ""}
            </div>
        </div>
    )
}