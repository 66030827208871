import "./MenuOpenButton.css";
import MenuCss from "../menu-items/MenuItems.module.css";
import React, { useEffect, useRef } from "react";

/**
 * @param {{targetRef: React.MutableRefObject<HTMLElement>}} props
 */
function MenuOpenButton(props) {

    /**@type {React.MutableRefObject<HTMLElement>} */
    const btnRef = useRef(null);

    const tsBorderHandler = (menu) => {
        if(menu.classList.contains(MenuCss.open)) {
            menu.style.borderTop = "1px solid var(--re-gray)";
        }
    }

    const teBorderHandler = (menu) => {
        if(!menu.classList.contains(MenuCss.open)) {
            menu.style.borderTop = "1px solid transparent";
        }
    }

    useEffect(() => {
        btnRef.current.onclick = () => {
            btnRef.current.classList.toggle("open");
            props.targetRef.current.classList.toggle(MenuCss.open);
            props.targetRef.current.addEventListener("transitionstart", 
                (e) => {tsBorderHandler(props.targetRef.current)});

            props.targetRef.current.addEventListener("transitionend", 
                (e) => {teBorderHandler(props.targetRef.current)});
        }
    }, []);

    return (
        <div id="menu1Button" ref={btnRef}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div className="sr-only">Abrir / Cerrar menú</div>
        </div>
    )
}

export default MenuOpenButton;