import 'react-phone-number-input/style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Css from "./ContactForm.module.css";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { TextContactInput } from "./inputs/TextContactInput";
import { getClientFromCache, registerClient, saveClientToCache, validateClient, validateEmail, validateName } from "../../services/client-service";
import PhoneInput from 'react-phone-number-input'
import { useEffect, useRef, useState } from "react";
import PhoneContactInput from './inputs/PhoneContactInput';
import ThanksComponent from './thanks/ThanksComponent';
import { RETagManager } from '../../utils/gtm-utils';

export default function ContactForm(props) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [sendInformation, setSendInformation] = useState(true);
    const [comment, setComment] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [twoCols, setTwoCols] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        calcCols();
        window.addEventListener("resize", (e) => {
            calcCols();
        });

        const clientFromCache = getClientFromCache();
        if(clientFromCache) {
            if(clientFromCache.first_name) 
                setFirstName(clientFromCache.first_name);
            if(clientFromCache.last_name) 
                setLastName(clientFromCache.last_name);
            if(clientFromCache.phone) 
                setPhone(clientFromCache.phone);
            if(clientFromCache.email)
                setEmail(clientFromCache.email);
            if(typeof clientFromCache.send_information == "boolean") {
                setSendInformation(clientFromCache.send_information);
                console.log(clientFromCache.send_information)
            }
        }
    }, []);

    const calcCols = () => {
        if(formRef.current) {
            const breakpoint = props.breakpoint || 400;
            const formWidth = formRef.current.offsetWidth;
            if(formWidth >= breakpoint) {
                setTwoCols(true);
            } else {
                setTwoCols(false);
            }
        }
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const client = {
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
            "phone": phone,
            "id_country": props.id_country,
            "id_region": props.id_region,
            "id_city": props.id_city,
            "comment": comment,
            "send_information": sendInformation,
            "id_property": props.id_property
        }

        if(validateClient(client) && !loading && (props.disabled !== true)) {
            setLoading(true);
            registerClient(client).then((response) => {
                setSent(true);
                setLoading(false);
                console.log(response);
                RETagManager.sendUserRegistrationEvent(response);
            }).catch((error) => {
                setError(true);
                setLoading(false);
                RETagManager.sendFailToRegisterUserEvent({error: JSON.stringify(error)});
            }).then(() => {
                saveClientToCache(client);
            });
        }
    }

    if(sent) {
       return <ThanksComponent firstName={firstName}/>
    }

    return (
        <div className="p-2 contact-div-bg">
            <form onSubmit={onSubmit} ref={formRef}>
                <div className={Css.mainDiv}>
                    <div className={Css.titleDiv}>
                        {props.title ? (
                            <p className="h3">{props.title}</p>
                        ) : (null)}
                        {props.subtitle ? (
                            <p>{props.subtitle}</p>
                        ) : null}
                        {(props.title || props.subtitle) ? (
                            <hr className='m-0' />
                        ) : null}
                    </div>
                    {error ? (
                        <div className={Css.errorDiv}>
                            <p>Ocurrió un error cuendo intentamos registrar su información. Por favor intente nuevamente.</p>
                        </div>
                    ) : (
                        null
                    )}
                    <div className='row'>
                        <div className={twoCols ? "col-6" : "col-12"} >
                            <div className={Css.formGroup}>
                                <label className={Css.label}>Nombres*</label>
                                <TextContactInput
                                    id="first_name" 
                                    name="first_name" 
                                    displayName="Nombre"
                                    // placeholder="Juan Fernando" 
                                    validator={validateName} 
                                    value={firstName}
                                    setValue={setFirstName}/>
                            </div>
                        </div>
                        <div className={twoCols ? "col-6" : "col-12"} >
                            <div className={Css.formGroup}>
                                <label className={Css.label}>Apellidos*</label>
                                <TextContactInput 
                                    id="last_name" 
                                    name="last_name" 
                                    displayName="Apellido"
                                    // placeholder="Perez Gaviria" 
                                    validator={validateName}
                                    value={lastName}
                                    setValue={setLastName}/>
                            </div>
                        </div>
                    </div>
                    <div className={Css.formGroup}>
                        <label className={Css.label}>Teléfono*</label>
                        <PhoneContactInput 
                            displayName="Numero de teléfono"
                            value={phone}
                            setValue={setPhone}/>
                    </div>
                    <div className={Css.formGroup}>
                        <label className={Css.label}>Correo*</label>
                        <TextContactInput 
                            id="email" 
                            name="email" 
                            displayName="Correo"
                            // placeholder="Ej: correo@ejemplo.com" 
                            validator={validateEmail}
                            value={email}
                            setValue={setEmail}/>
                    </div>
                    <div className={Css.formGroup}>
                        <label className={Css.label}>Comentarios</label>
                        <textarea 
                            className={"form-control " + Css.input}
                            id="comment" 
                            name="comment"
                            onChange={e => setComment(e.target.value)}
                            value={comment} />
                    </div>
                    <div className={Css.formGroup}>
                        <input 
                            type="checkbox" 
                            id="send_information" 
                            name="send_information" 
                            className="form-check-input" 
                            checked={sendInformation}
                            onChange={e => {setSendInformation(e.target.checked)}}
                            />

                        <label 
                            htmlFor="send_information" 
                            className={"form-check-label ps-2 " + Css.sendInfoChkLab}
                            >Quiero recibir información por correo</label>
                    </div>
                    <button className={"re-bg-blue w-100 re-color-white " + Css.submitBtn + " " + ((props.disabled === true) ? Css.disabled : "")}>
                        <div>
                            <span className="material-icons">mail</span>
                            {/* <img className={"img-fluid " + (loading ? "d-none" : "")} src="/icons/email_icon_white.png"/> */}
                            <span className="ps-1">{loading ? "Enviando..." : "Enviar"}</span>
                        </div>
                    </button>
                    <a  className={"d-flex justify-content-center no-dec-link "}
                        href="https://wa.me/573228547342" 
                        target="_blank" 
                        rel="noopener noreferer"
                        onClick={() => RETagManager.sendWhatsAppAttemptEvent()}
                    >
                        <div className="re-color-blue">
                            <FontAwesomeIcon icon={faWhatsapp} />
                            <span className="ps-2">Contactar por WhatsApp</span>
                        </div>
                    </a>
                    <div className={Css.privacyNoticeDiv}>
                        <p>
                            ** Al enviar este formulario usted acepta haber leido
                            y autoriza el tratamiento de datos personales de 
                            acuerdo con las políticas establecidas en el&nbsp;
                            <a href="/aviso-privacidad/" target='_blank'>
                                Aviso de Privacidad
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </form>
        </div>
    )
}