/**
 * @returns {{value: number, label: string}[]}
 */
export function getPropertyStates() {
    return [
        {value: 1, label: "Nuevo"},
        {value: 2, label: "Usado"},
        {value: 3, label: "Proyecto"},
        {value: 4, label: "En Construcción"}
    ]
}