
/**
 * @param {number} propId
 */
export async function registerPropertyVisit(propId) {
    const url = "/api/v1/properties/" + propId + "/register-visit";

    const response = await fetch(url, {method: "POST"});
    if(response.status !== 200) {
        throw new Error("Unable to fetch " + url + ". API responded with code: " + response.status);
    }

    const data = await response.text();

    return data;
}