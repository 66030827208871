import { Link } from "react-router-dom";
import Css from "./PropertyListItem.module.css";
import { getAreaUnitElement } from "../../../utils/area-utils";

/**
 * @param {{
 * property: Object.<string, any>,
 * loading: boolean
 * }} props
 */
export default function PropertyListItem(props) {
    
    const getAreaUnit = () => {
        const property = props.property;
        let unit = "M2";
        if(property.area) {
            unit = property.unit_area_label;
        } else if(property.built_area) {
            unit = property.unit_built_area_label;
        }
        return (getAreaUnitElement(unit))
    };

    const getArea = () => {
        const prop = props.property;
        let area = "";
        if(prop.area) {
            area = prop.area;
        } else if(prop.built_area) {
            area = prop.built_area;
        }

        return area;
    }

    const priceTitle = props.property.for_sale === "true" ? "Precio de venta" : "Alquiler";
    const price = props.property.for_sale === "true" ? props.property.sale_price_label : 
        props.property.rent_price_label;
    const areaUnit = getAreaUnit();
    const area = getArea();


    return (
        <Link to={"/propiedades/" + props.property.id_property + "/"} target="_blank" style={{textDecoration: "none"}}>
            <div className={Css.mainDiv + " shadow"}>
                <div className={Css.imageDiv}>
                    {props.property.main_image.url ? (
                        <img className={Css.image} src={props.property.main_image.url} alt="Imágen de la propiedad" />
                    ) : (
                        <div className={Css.noImgDiv +" d-flex flex-column justify-content-center"}>
                            <div className="d-flex justify-content-center">
                                <p>La imágen de esta propiedad no está disponible en este momento</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className={Css.body}>
                    <h3 className={Css.title}>{props.property.title}</h3>
                    <p className={Css.priceTitle}>{priceTitle}</p>
                    <p className={Css.price}>{price} <span className={Css.currency}>COP</span></p>
                    <div className="row">
                        {area ? (
                            <div className={Css.attr + " col-4 col-sm-auto col-md-4"}>
                                <p className={Css.areaLab}>Area ({areaUnit})</p>
                                <p>{area}</p>
                            </div>
                        ) : "" }
                        {/*props.property.bedrooms && props.property.bedrooms != 0*/true ? (
                            <div className={Css.attr + " col-4 col-sm-auto col-md-4"}>
                                <p>Alcobas</p>
                                <p>{props.property.bedrooms}</p>
                            </div>
                        ) : ""}
                        {/*props.property.bathrooms && props.property.bathrooms != 0*/true ? (
                            <div className={Css.attr + " col-4 col-sm-auto col-md-4"}>
                                <p>Baños</p>
                                <p>{props.property.bathrooms}</p>
                            </div>
                        ) : ""}
                    </div>
                </div>
            </div>
        </Link>
    )
}