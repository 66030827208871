import { apiResponse2Options } from "../utils/api-utils";
import cacheFetch from "../utils/cache-fetch";

export async function getPropTypes(query_params = {}, cache = {}) {
    query_params.quantity = true;
    let url = "/api/v1/property-types";
    let propTypes = await cacheFetch(url, cache, query_params);
    propTypes = apiResponse2Options(propTypes, "id_property_type", "name");

    return propTypes;
}

export const PROPERTY_TYPE_LABELS = {
    singular: {
        1: "Casa",
        2: "Apartamento",
        3: "Local",
        4: "Oficina",
        5: "Lote / Terreno",
        6: "Lote / Terreno Comercial",
        7: "Finca",
        8: "Bodega",
        10: "Chalet",
        11: "Casa Campestre",
        12: "Hotel",
        13: "Finca / Hotel",
        14: "Aparta-Estudio",
        15: "Consultorio",
        16: "Edificio",
        17: "Lote / Terreno de playa",
        18: "Hostal",
        19: "Condominio",
        20: "Duplex",
        21: "Penthouse",
        22: "Bungalow",
        23: "Galpon industrial",
        24: "Casa de playa",
        25: "Piso",
        26: "Garaje",
        27: "Cortijo",
        28: "Cabaña",
        29: "Isla",
        30: "Nave Industrial",
        31: "Campo / Chacra / Quinta",
        32: "Terreno",
        33: "Ph"        
    },

    plural: {
        1: "Casas",
        2: "Apartamentos",
        3: "Locales",
        4: "Oficinas",
        5: "Lotes / Terrenos",
        6: "Lotes / Terrenos Comerciales",
        7: "Fincas",
        8: "Bodegas",
        10: "Chalets",
        11: "Casas Campestre",
        12: "Hoteles",
        13: "Fincas / Hoteles",
        14: "Aparta-Estudios",
        15: "Consultorios",
        16: "Edificios",
        17: "Lotes / Terrenos de playa",
        18: "Hostales",
        19: "Condominios",
        20: "Duplex",
        21: "Penthouses",
        22: "Bungalows",
        23: "Galpon industriales",
        24: "Casas de playa",
        25: "Pisos",
        26: "Garajes",
        27: "Cortijos",
        28: "Cabañas",
        29: "Islas",
        30: "Nave Industriales",
        31: "Campos / Chacras / Quintas",
        32: "Terrenos",
        33: "Phs"        
    },
}