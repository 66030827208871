import { useEffect, useState, useContext } from "react";
import { BUSINESS_TYPE_RENT, BUSINESS_TYPE_SALE } from "../business-type-select/BusinessTypeSelect";

import Select from "react-select";
import selectStyles from "../common/select-styles";
import { getCityZones, getLocationZones } from "../../../services/zone-service";
import { CacheContext } from "../../../App";
import LoadingField from "../loading-field/LoadingField";

/**
 * 
 * @param {{
 *  zone: {value, label}
 *  propType: {value, label},
 *  businessType: {value, label},
 *  city: {value, label},
 *  location: {value, label},
 *  setZone: Function,
 *  loading: boolean
 * }} props 
 * @returns 
 */
export default function ZoneSelect(props) {
    const [zones, setZones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cache = useContext(CacheContext);
        
    useEffect(() => {
        let query_params = {quantity: true};
        setIsLoading(true);
        setZones([]);

        if((!props.city) && (!props.location)) {
            setIsLoading(false);
            return;
        }

        if(props.propType) {
            query_params.id_property_type = props.propType.value;
        }

        if(props.businessType) {
            if(props.businessType.value === BUSINESS_TYPE_RENT) query_params.for_rent = true;
            else if(props.businessType.value === BUSINESS_TYPE_SALE) query_params.for_sale = true;
        }

        if(props.location) {
            const locationId = props.location.value;

            getLocationZones(locationId, query_params, cache).then(data => {
                setZones(data);
                setIsLoading(false);
            });
        } else if(props.city) {
            const cityId = props.city.value;

            getCityZones(cityId, query_params, cache).then(data => {
                setZones(data);
                setIsLoading(false);
            });
        }
    }, [
        props.propType,
        props.businessType,
        props.city,
        props.location
    ]);

    const onChangeHandler = (choice) => choice ? props.setZone(choice) : props.setZone(null);

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <Select
            className={"basic-single " + props.className}
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            name="Zona"
            options={zones}
            placeholder="Zona"
            styles={selectStyles()}
            noOptionsMessage={() => "No hay opciones"}
            isLoading={isLoading}
            loadingMessage={() => "Cargando..."}
            onChange={onChangeHandler}
            defaultValue={props.zone}
        />
    )
            

}