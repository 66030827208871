import { isPossiblePhoneNumber } from "react-phone-number-input";
import { capitalizeFirstLetters } from "../utils/general-utils";
import { v4 as uuidv4 } from "uuid";

export async function registerClient(client) {

    if(!validateClient(client)) {
        return Promise.reject("Invalid client");
    }

    if(!client.comment) {
        client.comment = "Request UUID: " + uuidv4();
    } else {
        client.comment += " -- Requiest UUID: " + uuidv4();
    }

    sanitizeClient(client);

    const options = {
        "method": "POST",
        "body": JSON.stringify(client),
    };
    
    const url = "/api/v1/clients";
    const response = await fetch(url, options);

    if(response.status !== 200)  {
        const err = Error("Unable to fetch " + url + ". API responded with code " + response.status);
        err.name = "API Error";
        throw err;
    }

    return await response.json();
}

export function getClientFromCache() {
    const clientStr = localStorage.getItem("re-client-cache");
    const client = JSON.parse(clientStr);
    return client;
}

export function saveClientToCache(client) {
    const clientStr = JSON.stringify(client);
    localStorage.setItem("re-client-cache", clientStr);
}

export const validateClient = (client) => {
    if(
        validateName(client.first_name) &&
        validateName(client.last_name) &&
        validateEmail(client.email) &&
        validatePhone(client.phone)
    ) {
        return true;
    }

    return false;
}

const sanitizeClient = (client) => {
    client.first_name = sanitizeName(client.first_name);
    client.last_name = sanitizeName(client.last_name);
    client.email = sanitizeEmail(client.email);

    // Set id_client_type to "Contacto Web"
    // See https://api.wasi.co/docs/guide/clients.html#tipos-de-cliente
    client["id_client_type"] = 8;

    // Delete comment key if empty
    if(client.comment && client.comment.trim() === "") {
        delete client.comment;
    }

    // Set default cunetry, region and city
    if(!client.id_country || !client.id_region || !client.id_city) {
        client.id_country = 1; // Colombia. See https://api.wasi.co/docs/guide/countries.html#scope
        client.id_region = 10; // Caldas. See https://api.wasi.co/docs/guide/regions.html
        client.id_city = 482; // Manizales. See https://api.wasi.co/docs/guide/cities.html
    }
}

export const validateName = (value) => {
    value = value.trim();
    if(value.length === 0) {
        return false;
    }

    return true;
}

const sanitizeName = (value) => {
    return capitalizeFirstLetters(value);
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

const sanitizeEmail = (email) => {
    return email.toLowerCase();
}

export const validatePhone = (phone) => {
    return String(phone).match(/^\+[0-9]+/) && isPossiblePhoneNumber(phone);
}