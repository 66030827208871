import { OrderByDefaultOption, OrderByOptions } from "../components/property-list/order-by-select/OrderBySelect";

/**
 * Adds GET query_parameters to url.
 * URL must not have query_parameters previously.
 * I.E. "http://google.com" is valid as url.
 * "http://google.com?s=abc" is NOT valid as url.
 * @param {string} url
 * @param {Object.<string, string>} query_params
 * @returns {string}
 */
export function addQueryParams2Url(url, query_params) {
    url += "?";
    for (let param in query_params) {
        if(query_params[param] !== null) {
            url += encodeURI(param) + "=" + encodeURI(query_params[param]) + "&";
        }
    }

    url = url.replace(/(\?|&)$/, "");
    return url;
}

/**
 * Returns window.location.pathname
 * @param {boolean} query_params if true, appends window.location.search
 * to returned value
 * @returns {string}
 */
export function getCurrentUrl(query_params = false) {
    if(query_params === true) {
        return window.location.pathname + window.location.search;
    }

    return window.location.pathname;
}

/**
 * Returns current url query parameters as an object
 * @returns {Object.<string, string>}
 */
export function getCurrentQueryParams() {
    let qpStr = window.location.search;
    qpStr = qpStr.replace("?", "");
    let qpList = qpStr.split("&");
    let qp = {};
    qpList.forEach((param) => {
        const nameVal = param.split("=");
        if(nameVal[0] && nameVal[1]) {
            qp[nameVal[0]] = decodeURI(nameVal[1]);
        }
    });

    return qp;
}

/**
 * Returns an object with property search variables present in the current
 * URL.
 * @param {Object.<string, string | number>[]} propertyTypesLst lista con los tipos de propiedades
 * @param {Object.<string, number>[]} businessTypesLst lista con los tipos de negocio
 * @param {Object.<string, string | number>[]} citiesLst lista con las ciudades
 * @returns {{
 *      propType: {value: number, label: string},
 *      businessType: {value: string, label: string},
 *      city: {value: number, label: string},
 *      status: string
 * }}
 */
export function getPropertySearchVars(propertyTypesLst, businessTypesLst, citiesLst) {
    let url = getCurrentUrl();
    const errorRet = {status: "error", message: "invalid URL"};
    if(url.indexOf("/propiedades") < 0) {
        return errorRet;
    }

    url = url.replace("/propiedades", "");
    let tempVars = url.split("/");
    let vars = [];

    tempVars.forEach((variable) => {
        if(variable === "") return;
        vars.push(variable);
    })

    if(vars.length > 3) {
        return errorRet;
    }

    const retDict = {
        propType: null,
        businessType: null,
        city: null,
        status: "success"
    };

    let propertyTypes = {};
    let businessTypes = {};
    let cities = {};

    propertyTypesLst.forEach((pt) => {
        const value = pt.value;
        let label = pt.label
        label = encodePropertyVars(label);
        propertyTypes[label] = {value: value, label: pt.label};
    });

    businessTypesLst.forEach((bt) => {
        const value = bt.value;
        let label = bt.label
        label = encodePropertyVars(label);
        businessTypes[label] = {value: value, label: bt.label};
    });

    citiesLst.forEach((ct) => {
        const value = ct.value;
        let label = ct.label;
        label = encodePropertyVars(label);
        cities[label] = {value: value, label: ct.label};
    });

    if(vars.length === 1) {
        if(vars[0] in propertyTypes) {
            retDict.propType = {value: propertyTypes[vars[0]].value, label: propertyTypes[vars[0]].label}
        } else if(vars[0] in businessTypes) {
            retDict.businessType = {value: businessTypes[vars[0]].value, label: businessTypes[vars[0]].label}
        } else if(vars[0] in cities) {
            retDict.city = {value: cities[vars[0]].value, label: cities[vars[0]].label}
        } else {
            return errorRet;
        }
    } else if(vars.length === 2) {
        if(vars[1] in businessTypes) {
            if(vars[0] in propertyTypes) {
                retDict.propType = {value: propertyTypes[vars[0]].value, label: propertyTypes[vars[0]].label}
                retDict.businessType = {value: businessTypes[vars[1]].value, label: businessTypes[vars[1]].label}
            } else {
                return errorRet;
            }
        } else if(vars[1] in cities) {
            if(vars[0] in propertyTypes) {
                retDict.propType = {value: propertyTypes[vars[0]].value, label: propertyTypes[vars[0]].label}
                retDict.city = {value: cities[vars[1]].value, label: cities[vars[1]].label}
            } else if(vars[0] in businessTypes) {
                retDict.businessType = {value: businessTypes[vars[0]].value, label: businessTypes[vars[0]].label}
                retDict.city = {value: cities[vars[1]].value, label: cities[vars[1]].label}
            } else {
                return errorRet;
            }
        } else {
            return errorRet;
        }
    } else if(vars.length === 3) {
        if(
            vars[0] in propertyTypes &&
            vars[1] in businessTypes &&
            vars[2] in cities
        ) {
            retDict.propType = {value: propertyTypes[vars[0]].value, label: propertyTypes[vars[0]].label}
            retDict.businessType = {value: businessTypes[vars[1]].value, label: businessTypes[vars[1]].label}
            retDict.city = {value: cities[vars[2]].value, label: cities[vars[2]].label}
        } else {
            return errorRet;
        }
    }

    return retDict;
}

/**
 * 
 * @param {import("../components/property-search/advanced-property-search-form/AdvancedPropSearchForm").
 *  FilterParams} fp
 * @returns {string} url
 */
export function getPropertySearchUrl(
        fp,
        page=1,
        orderByValue=OrderByDefaultOption.value
    ) {
    let url = "/propiedades/"
    if(fp.propType) {
        url += encodePropertyVars(fp.propType.label) + "/";
    }
    if(fp.businessType) {
        url += encodePropertyVars(fp.businessType.label) + "/";
    }
    if(fp.city) {
        url += encodePropertyVars(fp.city.label) + "/";
    }

    const byLabel = [
        "location",
        "zone",
        "propState",
    ];

    const byValue = [
        "rooms",
        "bathrooms",
        "minPrice",
        "maxPrice"
    ]

    let qp = {};

    for(let key in fp) {
        if(fp[key]) {
            if(byLabel.includes(key)) {
                qp[key] = encodePropertyVars(fp[key].label);
            } else if(byValue.includes(key)) {
                qp[key] = encodeURI(fp[key].value);
            }
        }
    }

    
    if(orderByValue.order !== OrderByDefaultOption.value.order) {
        qp["order"] = orderByValue.order;
    }
    
    if(orderByValue.order_by !== OrderByDefaultOption.value.order_by) {
        qp["by"] = orderByValue.order_by;
    }
    
    if(page !== 1) {
        qp["page"] = page;
    }

    url = addQueryParams2Url(url, qp);
    
    return url;
}

/**
 * Returns the location that corresponds to the location
 * specified in current URL query_parameters
 * @param {{value, label}[]} locations
 * @returns {{value: number, label: string} | null}
 */
export function getLocationFromQueryParams(locations) {
    const qp = getCurrentQueryParams();
    let location = null;
    if(qp.location) {
        location = getOptionFromLabel(qp.location, locations);
    }

    return location;
}

export function getZoneFromQueryParams(zones) {
    const qp = getCurrentQueryParams();
    let zone = null;
    if(qp.zone) {
        zone = getOptionFromLabel(qp.zone, zones);
    }

    return zone;
}

export function getPropStateFromQueryParams(propStates) {
    const qp = getCurrentQueryParams();
    let propState = null;
    if(qp.propState) {
        propState = getOptionFromLabel(qp.propState, propStates);
    }

    return propState;
}

export function getRoomsFromQueryParams(roomQuantities) {
    const qp = getCurrentQueryParams();
    let rooms = null;
    if(qp.rooms) {
        rooms = getOptionFromValue(qp.rooms, roomQuantities);
    }

    return rooms;
}

export function getBathroomsFromQueryParams(bathroomQuantities) {
    const qp = getCurrentQueryParams();
    let bathrooms = null;
    if(qp.bathrooms) {
        bathrooms = getOptionFromValue(qp.bathrooms, bathroomQuantities);
    }

    return bathrooms;
}

export function getMinPriceFromQueryParams() {
    const qp = getCurrentQueryParams();
    let minPrice = null;
    if(qp.minPrice) {
        if(/[0-9]*/.test(qp.minPrice)) {
            minPrice = {value: parseInt(qp.minPrice), label: qp.minPrice};
        }
    }

    return minPrice
}

export function getMaxPriceFromQueryParams() {
    const qp = getCurrentQueryParams();
    let maxPrice = null;
    if(qp.maxPrice) {
        if(/[0-9]*/.test(qp.maxPrice)) {
            maxPrice = {value: parseInt(qp.maxPrice), label: qp.maxPrice};
        }
    }

    return maxPrice
}

export function getBusinessTypeFromQueryParams(businessTypes) {
    const qp = getCurrentQueryParams();
    let businessType = null;
    if(qp.businessType) {
        businessType = getOptionFromLabel(qp.businessType, businessTypes);
    }

    return businessType;
}

export function getOrderByFromQueryParams() {
    const qp = getCurrentQueryParams();
    const orderByVal = {...OrderByDefaultOption.value};
    if(qp.by) {
        orderByVal.order_by = qp.by;
    }
    if(qp.order) {
        orderByVal.order = qp.order;
    }

    for(let option of OrderByOptions) {
        if (JSON.stringify(option.value) === JSON.stringify(orderByVal)) {
            return option;
        }
    }

    return OrderByDefaultOption;
}

export function getPageFromQueryParams() {
    const qp = getCurrentQueryParams();
    if(qp.page) {
        const page = parseInt(qp.page);
        if(!isNaN(page)) {
            return page;
        }
    }
    return 1;
}

/**
 * Returns option in options that has the specified value
 * @param {number | string} value 
 * @param {{value, label}[]} options
 * @returns {{value: string | number, label: string} | null}
 */
function getOptionFromValue(value, options) {
    let retOption = null;
    options.forEach((option) => {
        const val = encodeURI(option.value);
        if(val === value) {
            retOption = option;
        }
    });

    return retOption;
}

/**
 * Returns option in options that has the specified label
 * @param {string} label 
 * @param {{value, label}[]} options
 * @returns {{value: string | number, label: string} | null}
 */
function getOptionFromLabel(label, options) {
    let retOption = null;
    options.forEach((option) => {
        const lab = encodePropertyVars(option.label);
        if(lab === label) {
            retOption = option;
        }
    });

    return retOption;
}

/**
 * Encodes property variable
 * @param {string} variable 
 * @returns {string} encoded variable
 */
export function encodePropertyVars(variable) {
    variable = variable.toLocaleLowerCase()
        .replaceAll(" / ", "-")
        .replaceAll("/", "-")
        .replaceAll(" ", "_")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    return encodeURI(variable);
}

// /**
//  * Transforms an object of form {<label>: <value>}
//  * to an object of form {value: <value>, label: <label>}
//  * @param {Object} keyLabel
//  * @returns {{value: any, label: any}}
//  */
// function labelValue2ExplicitValueLabel(labelValueObject) {
//     const label = Object.keys(labelValueObject)[0];
//     const value = labelValueObject[label];
//     return {value: value, label: label}
// }

// /**
//  * Transforms an object of form {value: <value>, label: <label>}
//  * to an object of form {<label>: <value>}
//  * @param {{value: any, label: any}} ExpObject
//  * @returns {Object}
//  */
// function ExplicitValueLabel2LabelValue(ExpObject) {
//     let retObj = {}
//     retObj[ExpObject.label] = ExpObject.value;
//     return retObj;
// }