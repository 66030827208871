import { useEffect, useRef, useState } from "react";
import Css from "./PriceField.module.css";
import LoadingField from "../loading-field/LoadingField";

/**
 * 
 * @param {{
 *  price: {value: number, label},
 *  placeholder: string,
 *  prefix: string,
 *  setPrice: Function,
 *  loading: boolean
 * }} props 
 */
export default function PriceField(props) {
    const [active, setActive] = useState(false);
    const [showClear, setShowClear] = useState(false);
    const init = useRef(false);
    
    /**
     * @type {React.MutableRefObject<HTMLInputElement>}
     */
    const ref = useRef(null);

    const onClickHandler = (e) => {
        ref.current.focus();
    };

    const onBlurHandler = (e) => {
        let price = ref.current.value.replaceAll(/[^0-9]/g, "");;
        if(price === "" || price === "0") {
            props.setPrice(null);  
        } else {
            props.setPrice({value: parseInt(price), label: parseInt(price)});
        }

        if(ref.current.value === "") {
            setActive(false);
        }
    }

    const onFocusHandler = () => {
        setActive(true);
    };

    const onChangeHandler = () => {
        /**
         * @type {string}
         */
        let value = ref.current.value;
        value = value.replaceAll(/[^0-9]/g, "");
        
        if(value === "") {
            ref.current.value = "";
            setShowClear(false);
            return;
        }

        let numValue = value ? parseInt(value) : 0;
        ref.current.value = numValue.toLocaleString("es-CO");
        setShowClear(true);
    }

    const onClearHandler = (e) => {
        e.stopPropagation();
        ref.current.value = "";
        ref.current.blur();
        setActive(false);
        setShowClear(false);
        props.setPrice(null);
    }

    useEffect(() => {
        if(props.loading === false && init.current === false) {
            if(props.price && !isNaN(props.price.value)) {
                ref.current.value = props.price.value;
                setActive(true);
            }
            init.current = true;
            onChangeHandler();
        }
    }, [props.loading]);

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <div className={Css.wrapper + " " + (active ? Css.active : "")} onClick={onClickHandler}>
            <div className={Css.placeholderDiv}>
                <div>
                    {props.placeholder}
                </div>
            </div>
            <div className={Css.inputWrapper}>
                <div>
                    <div className={Css.prefix}>{props.prefix}: </div>
                </div>
                <div className="flex-grow-1">
                    <input
                        ref={ref}
                        type="text"
                        className={Css.txtInput}
                        onBlur={onBlurHandler}
                        onFocus={onFocusHandler}
                        onChange={onChangeHandler}
                    />
                </div>
                <div onClick={onClearHandler}>
                    <div className={Css.clear + " " + (showClear ? Css.show : "")}>
                        &times;
                    </div>
                </div>
            </div>
        </div>
    )
}