import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Css from "./PropFeatureList.module.css";
import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";

/**
 * 
 * @param {{features: {nombre}[], title}} props 
 * @returns 
 */
export default function PropCharacteristicList(props) {
    return props.features ? (
        <div className={Css.wrapper}>
            <h2 className={Css.title}>{props.title}</h2>
            <div className="row">
                {props.features.map((feature) => {
                    return(<div className={"col-12 col-lg-6 col-xl-6 col-xxl-4"} key={feature.nombre}>
                        <div className={Css.feature}>
                            <img src="/re-icons/Check_Icon.png" className="img-fluid" />
                            <span title={feature.nombre}>&nbsp;&nbsp;{feature.nombre}</span>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    ) : ""
}