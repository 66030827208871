import TagManager from "react-gtm-module";

export const RETagManager = {

    /**
     * Initializes tag manager.
     */
    initialize() {
        const gtmId = "GTM-MXJ39DP2";
        TagManager.initialize({ gtmId });
    },

    /**
     * Adds {event: "pageview"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendPageViewEvent(data = {}) {
        TagManager.dataLayer({
            event: "pageview",
            ...data
        });
    },

    /**
     * Adds {event: "user_registration"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendUserRegistrationEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "user_registration",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "failed_user_registration"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendFailToRegisterUserEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "failed_user_registration",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "whatsapp_attempt"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendWhatsAppAttemptEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "whatsapp_attempt",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "call_attempt"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendCallAtemptEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "call_attempt",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "email_attempt"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendEmailAtemptEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "email_attempt",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "instagram_click"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendInstagramLinkClickEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "instagram_click",
                ...data
            }
        });
    },
    
    /**
     * Adds {event: "facebook_click"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendFacebookClickEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "facebook_click",
                ...data
            }
        });
    },

    /**
     * Adds {event: "developers_email_attempt"} to Google Tag
     * Manager's data layer.
     * @param {{[x: string]: any}} data - data to include
     * in the data layer
     */
    sendDevsEmailAtemptEvent(data = {}) {
        TagManager.dataLayer({
            dataLayer: {
                event: "developers_email_attempt",
                ...data
            }
        });
    }
}
