import MainMenu from "./menu/MainMenu";
import HeaderTopDiv from "./top-div/HeaderTopDiv";

function Header() {
    return (
        <header>
            <HeaderTopDiv />
            <MainMenu />
        </header>
    )
}

export default Header;