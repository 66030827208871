import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { getBathroomsFromQueryParams, getCurrentQueryParams, getLocationFromQueryParams, getMaxPriceFromQueryParams, getMinPriceFromQueryParams, getOrderByFromQueryParams, getPageFromQueryParams, getPropStateFromQueryParams, getPropertySearchUrl, getPropertySearchVars, getRoomsFromQueryParams, getZoneFromQueryParams } from "../../utils/url-utils";
import Css from "./PropertySearchPage.module.css";
import AdvancedPropSearchForm from "../../components/property-search/advanced-property-search-form/AdvancedPropSearchForm";
import { useLocation, useNavigate } from "react-router-dom";
import { CacheContext } from "../../App";
import { businessTypes } from "../../components/property-search/business-type-select/BusinessTypeSelect";
import { PROPERTY_TYPE_LABELS, getPropTypes } from "../../services/property-type-sercice";
import { getCities } from "../../services/city-service";
import { getLocations } from "../../services/location-service";
import { getCityZones, getLocationZones } from "../../services/zone-service";
import { bathroomQuantities } from "../../components/property-search/bathroom-quantity-select/BathroomQuantitySelect";
import { propStates } from "../../components/property-search/property-state-select/PropertyStateSelect";
import { roomQuantities } from "../../components/property-search/room-quantity-select/RoomQuantitySelect";
import PropertyList from "../../components/property-list/PropertyList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import Error from "../../components/errors/Error";
import PropSearchBreadcrumb from "../../components/property-search/bread-crumb/PropSearchBreadcrumb";
import OrderBySelect, { OrderByDefaultOption } from "../../components/property-list/order-by-select/OrderBySelect";
import Pagination from "../../components/pagination/Pagination";
import ContactForm from "../../components/contact-form/ContactForm";

export default function PropertySearchPage(props) {


    const [initFilters, setInitFilters] = useState(null);
    const [filters, _setFilters] = useState(null);
    const [error, setError] = useState(false);
    const [orderBy, _setOrderBy] = useState(getOrderByFromQueryParams());
    const [page, _setPage] = useState(getPageFromQueryParams());
    const [total, setTotal] = useState(null);
    const propListWrapperRef = useRef();
    const location = useLocation();

    const navigate = useNavigate();

    const setFilters = (filters) => {
        _setFilters(filters);
    }
    
    /**
     * @param {import("../../components/property-search/advanced-property-search-form/AdvancedPropSearchForm").
     *  FilterParams} fp
     */
    const onSubmitHandler = (fp) => {
        setTotal(null);
        const url = getPropertySearchUrl(fp, 1, orderBy.value);
        setError(false);
        setFilters(fp);
        _setPage(1);
        navigate(url);
    }

    const setOrderBy = (orderBy) => {
        _setPage(1);
        _setOrderBy(orderBy);
        const url = getPropertySearchUrl(filters, 1, orderBy.value);
        navigate(url);
    }

    const setPage = (page) => {
        _setPage(page);
        const url = getPropertySearchUrl(filters, page, orderBy.value);
        console.log(url);
        navigate(url);
        if(propListWrapperRef.current) {
            propListWrapperRef.current.scrollIntoView();
        }
    }

    const cache = useContext(CacheContext);

    const getInitialFilterParams = async () => {

        let initFilters = {
            propType: null,
            businessType: null,
            city: null,
            location: null,
            zone: null,
            propState: null,
            rooms: null,
            bathrooms: null,
            minPrice: null,
            maxPrice: null,
            orderBy: null,
            page: null
        };

        //Get list of property types and list of cities
        const [propTypes, cities] = await Promise.all([
            getPropTypes({}, cache),
            getCities({}, cache)
        ]);

        const sv = getPropertySearchVars(propTypes, businessTypes, cities);
        if(sv.status === "error") {
            setError(true);
        }

        delete sv.status;

        initFilters = {...initFilters, ...sv};

        const qp = getCurrentQueryParams();


        if("location" in qp) {
            if(initFilters.city) {
                let locations = await getLocations(initFilters.city.value, {}, cache);
                initFilters.location = getLocationFromQueryParams(locations);
            }
        }

        if("zone" in qp) {
            if(initFilters.location) {
                let zones = await getLocationZones(initFilters.location.value, {}, cache)
                initFilters.zone = getZoneFromQueryParams(zones);
            } else if(initFilters.city) {
                let zones = await getCityZones(initFilters.city.value, {}, cache);
                initFilters.zone = getZoneFromQueryParams(zones);
            }
        }

        initFilters.bathrooms = getBathroomsFromQueryParams(bathroomQuantities);
        initFilters.maxPrice = getMaxPriceFromQueryParams();
        initFilters.minPrice = getMinPriceFromQueryParams();
        initFilters.propState = getPropStateFromQueryParams(propStates);
        initFilters.rooms = getRoomsFromQueryParams(roomQuantities);

        return initFilters;
    }

    useEffect(() => {
        getInitialFilterParams().then(initFilters => {
            setInitFilters(initFilters);
            setFilters(initFilters);
        });
    }, []);

    let title = null;
    if(filters) {
        title = "";
        if(filters.propType) {
            title +=  PROPERTY_TYPE_LABELS.plural[filters.propType.value] + " ";
        } else {
            title += "Propiedades ";
        }

        if(filters.businessType) {
            title += "en " + filters.businessType.label + " ";
        }

        if(filters.city) {
            title += "en " + filters.city.label + " ";
        }

        if(!filters.businessType && !filters.city) {
            title += "en Colombia";
        }

        title = title.trim();
        title = title.replace("/", "-");
    }

    let canonical = "https://restrepoecheverri.com/propiedades" + location.pathname;
    if(!(canonical.endsWith("/"))) {
        canonical += "/";
    }

    return(
        <div className="mb-5">
            <Helmet>
                <title>{title || "Propiedades disponibles"} | Inmobiliaria Restrepo Echeverri</title>
                <meta name="description" content="¡Encuentre el inmueble de sus sueños! Con nuestro buscador, encuentre rápidamente el inmueble que está buscando y contáctenos para agendar una cita." />
                <link rel="canonical" href={canonical} />
            </Helmet>
            <div className={Css.advancedSearchWrapper}>
                <div className="container">
                    <AdvancedPropSearchForm onSubmit={onSubmitHandler} defaultValues={initFilters}/>
                </div>
            </div>
            {error ? (<ErrorComponent />) : (
                <>  
                    <div className="container" ref={propListWrapperRef}>
                        <div className="row">
                            <div className={"col-12 col-md " + Css.breadcrumbDiv}>
                                <span className={Css.breadcrumbSpan}>
                                    <PropSearchBreadcrumb filters={filters} className={Css.breadcrumb} />
                                    {total ? (<strong>{total} </strong>) : null}
                                    {title ? (<h1 className="d-inline text-size-small">{title}</h1>) : null}
                                </span>
                                <span className="hidden-seo-tag">
                                    <h2>Inmobiliarias en Manizales</h2>
                                    <h2>Venta de propiedades en Manizales</h2>
                                </span>
                            </div>
                            <div className={"col-12 col-md-auto " + Css.orderByWrapper}>
                                <div className={Css.orderByDiv}>
                                    <OrderBySelect orderBy={orderBy} setOrderBy={setOrderBy}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <PropertyList 
                            filters={filters}
                            take={12}
                            orderBy={orderBy}
                            setTotal={setTotal}
                            page={page}
                            />
                    </div>
                    <div className="container">
                        <div className={Css.paginationWrapper}>
                            <div>
                                <Pagination page={page} take={12} pgsToDisplay={5} total={total} setPage={setPage} />
                            </div>
                        </div>
                        <div className={Css.contactWrapper}>
                            <div className={Css.contactDiv}>
                                <ContactForm 
                                    title="¿Aún no encuentras tu inmueble?"
                                    subtitle="Contáctanos para recibir atención personalizada"/>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>        
    )
}

function ErrorComponent() {
    return (
        <Error title="Error | Inmobiliaria Restrepo Echeverri">
            <div className={"container " + Css.errorDiv}>
                <div><FontAwesomeIcon icon={faTriangleExclamation} /></div>
                <p className="text-size-3">Oops. Hay un error con los filtros de búsqueda</p>
                <p className="text-size-2">
                    Ésto puede ocurrir porque navegaste a una URL inválida o porque hay algún problema en nuestro servidor.
                </p>
                <p className="text-size-2">Intenta <a href="/propiedades/">eliminar todos los filtros</a></p>
            </div>
        </Error>
    )
}