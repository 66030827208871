import { Helmet } from "react-helmet";

/**
 * @param {{title: string}} props
 */
export default function Error(props) {
    const title = props.title ? props.title : "Error";
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <>
                {props.children}
            </>
        </>
    )
}