import { apiResponse2Options } from "../utils/api-utils";
import cacheFetch from "../utils/cache-fetch";

/**
 * returns the zones of a given city
 * @param {number} cityId 
 * @param {Object.<string, string>} query_params 
 * @returns {Promise<{value: number, label: string}[]>}
 */
export async function getCityZones(cityId, query_params = {}, cache = {}) {
    query_params.quantity = true;
    let url = "/api/v1/cities/" + cityId + "/zones";
    let zones = await cacheFetch(url, cache, query_params);
    zones = apiResponse2Options(zones, "id_zone", "name");

    return zones;
}

/**
 * returns the zones of a given lovation
 * @param {number} cityId 
 * @param {Object.<string, string>} query_params 
 * @returns {Promise<{value: number, label: string}[]>}
 */
export async function getLocationZones(locationId, query_params = {}, cache = {}) {
    query_params.quantity = true;
    let url = "/api/v1/locations/" + locationId + "/zones";
    let zones = await cacheFetch(url, cache, query_params);
    zones = apiResponse2Options(zones, "id_zone", "name");

    return zones;
}

