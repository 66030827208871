import { useEffect, useState, useRef } from "react";
import PropertyDetail from "../../components/property-detail/PropertyDetail";
import { getCurrentUrl } from "../../utils/url-utils";
import { getPropertyById } from "../../services/property-service";
import { useNavigate } from "react-router-dom";
import { registerPropertyVisit } from "../../services/visit-service";
import Error from "../../components/errors/Error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Css from "./PropertyDetailPage.module.css";
import ContactForm from "../../components/contact-form/ContactForm";
import PropertyPrice from "../../components/property-detail/prop-price/PropertyPrice";
import useWindowDimensions from "../../hooks/WindowDimensionsHook";
import { Helmet } from "react-helmet";

export default function PropertyDetailPage() {

    const [property, setProperty] = useState(null);
    const [error, setError] = useState(false);
    const contactFormRef = useRef();
    const navigate = useNavigate();
    const {width} = useWindowDimensions();

    let hidePriceOnPropDetail = false;
    let hidePriceOnPropPage = true;
    if(width >= 992) {
        hidePriceOnPropDetail = true;
        hidePriceOnPropPage = false;
    }
    
    useEffect(() => {
        const url = getCurrentUrl();
        let propId = url.replace("/propiedades/", "").replace("/", "");
        propId = parseInt(propId);
        if(isNaN(propId)) {
            navigate("/404/");
        }

        getPropertyById(propId).then((property) => {
            const propImages = [];
            if(property.galleries) {
                property.galleries.forEach((gallery) => {
                    for(let key in gallery) {
                        if(key !== "id") {
                            propImages.push(gallery[key].url);
                        }
                    }
                });
            }
            property.propImages = propImages;
            setProperty(property);
        }).catch((error) => {
            if(error.name && error.name === "API Error 404") {
                setError(404);
            } else {
                setError(500);
            }
        });

        registerPropertyVisit(propId).then();
    }, []);

    if(error) {
        return <ErrorComponent />
    }

    const togleContactForm = () => {
        contactFormRef.current.scrollIntoView({behavior: "smooth"});
    }

    let id_country;
    let id_region;
    let id_city;
    let id_property;

    if (property) {
        id_country = property.id_country;
        id_region = property.id_region;
        id_city = property.id_city;
        id_property = property.id_property;
    }    

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 col-xxl-9">
                        <PropertyDetail property={property} hidePrice={hidePriceOnPropDetail}/>
                    </div>
                    <div className={"col-12 col-lg-4 col-xxl-3 " + Css.sidevarDiv} ref={contactFormRef}>
                        <div className={Css.stickyDiv}>
                            {hidePriceOnPropPage ? null : (
                                <div className={Css.priceDiv}>
                                    <PropertyPrice property={property} />
                                </div>
                            )}
                            <div className={Css.contactWrapper}>
                                <div className={Css.contactDiv}>
                                    <ContactForm 
                                        title="¡Estoy interesado!"
                                        disabled={(property ? false : true)}
                                        id_property={id_property}
                                        id_country={id_country}
                                        id_region={id_region}
                                        id_city={id_city}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Css.bottomDiv}>
                <div className="container">
                    <button className={Css.interestedBtn} onClick={togleContactForm}>¡Estoy interesado!</button>
                </div>
            </div>
        </>
    )
}

function ErrorComponent(props) {
    let errMsg = "Hubo un error cuando intentamos obtener la información de la propiedad.";
    if(props.code && props.code === 404) {
        errMsg = "No encontramos la propiedad a la que navegaste."
    }
    return (
        <Error title="Error | Inmobiliaria Restrepo Echeverri">
            <div className={"container " + Css.errorDiv}>
                <div><FontAwesomeIcon icon={faTriangleExclamation} /></div>
                <p className="text-size-3">Oops. {errMsg}</p>
                <p className="text-size-2">
                    Ésto puede ocurrir porque ingresaste un código inexistente,
                    porque la propiedad ya no está disponible o
                    porque hay algún problema en nuestro servidor.
                </p>
                <p className="text-size-2">Intenta <a href="/propiedades/">volver al buscador de propiedades</a></p>
            </div>
        </Error>
    )
}