import { PROPERTY_TYPE_LABELS } from "../../../services/property-type-sercice";
import { getPropertySearchUrl } from "../../../utils/url-utils";
import Css from "./PropSearchBreadcrumb.module.css";

/**
 * 
 * @param {{
 *  filters: import("../../property-search/advanced-property-search-form/AdvancedPropSearchForm").FilterParams }
 * } props 
 */
export default function PropSearchBreadcrumb(props) {
    /**
     * @type {{label: string, url: string}[]}
     */
    const breadcrumbArr = [{label: "Propiedades", url: "/propiedades/"}];
    if(props.filters) {
        if(props.filters.propType) {
            const fp = {propType: props.filters.propType};
            breadcrumbArr.push({
                label: PROPERTY_TYPE_LABELS.plural[props.filters.propType.value].replace("/", "-"),
                url: getPropertySearchUrl(fp)
            });
        }
        if(props.filters.businessType) {
            const fp = {
                propType: props.filters.propType,
                businessType: props.filters.businessType
            };
            breadcrumbArr.push({
                label: props.filters.businessType.label,
                url: getPropertySearchUrl(fp)
            });
        }
        if(props.filters.city) {
            const fp = {
                propType: props.filters.propType,
                businessType: props.filters.businessType,
                city: props.filters.city
            };
            breadcrumbArr.push({
                label: props.filters.city.label,
                url: getPropertySearchUrl(fp)
            });
        }
        return (
            <>
                <span>
                    {breadcrumbArr.map((bc) => {
                        return (
                            <span className={props.className}>
                                <a href={bc.url} className={Css.bcLabel}>{bc.label}</a>
                                <span className={Css.separator}>/</span>
                            </span>
                        )
                    })}
                </span>
            </>
        )
    } else {
        return (
            <span className={"animated-background " + Css.loading + " " + props.className}></span>
        )
    }

}