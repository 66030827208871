import { useEffect, useState, useContext } from "react";
import { BUSINESS_TYPE_RENT, BUSINESS_TYPE_SALE } from "../business-type-select/BusinessTypeSelect";
import { getLocations } from "../../../services/location-service";

import Select from "react-select";
import selectStyles from "../common/select-styles";
import { CacheContext } from "../../../App";
import LoadingField from "../loading-field/LoadingField";

/**
 * 
 * @param {{
 *  location: {value, label}
 *  propType: {value, label},
 *  businessType: {value, label},
 *  city: {value, label},
 *  setLocation: Function,
 *  loading: boolean
 * }} props 
 */
export default function LocationSelect(props) {
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cache = useContext(CacheContext);
        
    useEffect(() => {
        let query_params = {quantity: true};
        setIsLoading(true);
        setLocations([]);

        if(!props.city) {
            setIsLoading(false);
            return;
        }

        let cityId = props.city.value;

        if(props.propType) {
            query_params.id_property_type = props.propType.value;
        }

        if(props.businessType) {
            if(props.businessType.value === BUSINESS_TYPE_RENT) query_params.for_rent = true;
            else if(props.businessType.value === BUSINESS_TYPE_SALE) query_params.for_sale = true;
        }
        
        getLocations(cityId, query_params, cache).then(data => {
            setLocations(data);
            setIsLoading(false);
        });


    }, [
        props.propType,
        props.businessType,
        props.city
    ]);


    const changeHandler = choice => props.setLocation(choice ? choice : null);

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <Select
            className={"basic-single " + props.className}
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            name="Localidad"
            options={locations}
            placeholder="Localidad"
            styles={selectStyles()}
            noOptionsMessage={() => "No hay opciones"}
            isLoading={isLoading}
            loadingMessage={() => "Cargando..."}
            onChange={changeHandler}
            defaultValue={props.location}
        />
    )
            

}