export default function selectStyles() {
    return {
        control: (bs, state) => ({
                ...bs,
                borderColor: state.isFocused ? "#2684ff" : "transparent",
                "&:hover": {
                    borderColor: state.isFocused ? "#2684ff" : "transparent",
                },
                borderRadius: "10px"
        })
    }
}