import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Css from "./PropDetailList.module.css";
import { faCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { getPropertyStates } from "../../../services/property-state-service";

/**
 * @param {{property: Object.<string, any>}} props 
 */
export default function PropDetailList(props) {
    let propState = "";
    getPropertyStates().forEach((state) => {
        if(state.value === props.property.id_property_condition) {
            propState = state.label;
        }
    });
    
    const details = {
        "Código": props.property.id_property,
        "País": props.property.country_label,
        "Ciudad": props.property.city_label,
        "Localidad": props.property.location_label,
        "Zona / barrio": props.property.zone_label,
        "Año construcción": props.property.building_date,
        "Habitaciones": props.property.bedrooms,
        "Baños": props.property.bathrooms,
        "Parqueaderos": props.property.garages,
        "Estado": propState
    };

    if(props.property.maintenance_fee && props.property.maintenance_fee !== "0") {
        details["Administración"] = props.property.maintenance_fee_label;
    }

    return (
        <div className={Css.wrapper}>
            <h2 className={Css.title}>Detalles del imueble</h2>
            <div className="d-flex flex-wrap">
                {Object.keys(details).map((key) => {
                    if(details[key]) {
                        return <div className={"col-12 col-sm-6 col-md-4 col-lg-3"} key={key + details[key]}>
                            <div className={Css.detail}>
                                <p className={Css.detailName}>{key}</p>
                                <p className={Css.detailInfo}>{details[key]}</p>
                            </div>
                        </div>
                    }
                })}
            </div>
        </div>
    )
}