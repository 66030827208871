import { Link } from "react-router-dom";
import Css from "./MenuItems.module.css";
import { HashLink } from "react-router-hash-link";
import { useEffect } from "react";

/**
 * @param {{ref2: React.MutableRefObject<any>}} props 
 */
function MenuItems(props) {
    let cont = props.inContainer? "container" : "";

    
    return (
        <div className={Css.menuDiv + " " + props.className} id={props.id} ref={props.ref2}>
            <div className={cont}>
                <ul className={Css.ul}>
                    <li>
                        <a href="/">
                            Inicio
                        </a>
                    </li>
                    <li>
                        <a href="/propiedades/">
                            Propiedades
                        </a>
                    </li>
                    <li>
                        <a href="/sobre-nosotros/">
                            Sobre nosotros
                        </a>
                    </li>
                    <li>
                        <a href="/contactenos/">
                            Contáctenos
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MenuItems;