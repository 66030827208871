import { Helmet } from "react-helmet";
import ContactInformation from "../../components/contact-information/ContactInformation";
import PageWithSidebar from "../PageWidthSidebar/PageWithSidebar";

export default function PrivacyNoticePage() {
    return (
        <>
            <Helmet>
                <title>Aviso de Privacidad | Inmobiliaria Restrepo Echeverri</title>
                <meta name="description" content="Aviso de Privacidad de Inmobiliaria Restrepo Echeverri" />
                <link rel="canonical" href="https://restrepoecheverri.com/aviso-privacidad/" />
            </Helmet>
            <PageWithSidebar 
                sideBarContent={(
                    <ContactInformation />
                )}
            >
                <h1 className="mt-0">Aviso de privacidad</h1>
                <strong>
                    AVISO DE PRIVACIDAD Y AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS 
                    PERSONALES<br/><br/>
                </strong>
                <p className="text-align-justify">
                    INMOBILIARIA RESTREPO ECHEVERRI S.A.S identificada con
                    NIT.901763214-1.<br /><br />
                    Al hacer clic en la casilla de verificación / ingresar su 
                    información, autoriza de manera previa, expresa e inequívoca a 
                    INMOBILIARIA RESTREPO ECHEVERRI - NIT 901763214-1, (correo: 
                    marialeonor@restrepoecheverri.com, teléfono: +57 322 854 7342), como 
                    responsable del tratamiento, para realizar recolección, 
                    almacenamiento, utilización, circulación o supresión de los 
                    datos personales que sean recolectados con las siguientes 
                    finalidades: dar trámite a su solicitud, presentación de 
                    cotizaciones u ofertas relacionadas con los servicios prestados 
                    por el responsable, Suscripción y ejecución de contratos, 
                    Verificación de referencias, Publicidad, trade marketing, 
                    encuestas de opinión, prospección comercial. Como titular de los
                    datos tiene derecho al acceso actualización, rectificación, 
                    supresión de su información, revocatoria de la autorización, 
                    presentación de quejas ante la SIC. Es facultativo responder 
                    preguntas que versen sobre Datos Sensibles o sobre menores de 
                    edad. Las PQR relacionadas con el tratamiento de sus datos 
                    personales, podrá presentarlas al correo electrónico antes 
                    indicado.
                </p>
            </PageWithSidebar>
        </>
    )
}