import { useState } from "react";
import Css from "../ContactForm.module.css";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import es from 'react-phone-number-input/locale/es'
import { validatePhone } from "../../../services/client-service";

export default function PhoneContactInput(props) {

    const [isValid, setIsValid] = useState(true);
    const value = props.value;
    const setValue = props.setValue;

    const onBlur = (e) => {
        validate();
    }

    const onKeyDown = (e) => {
        if(e.key === "Enter") {
            validate();
        }
    }

    const validate = () => {
        if(value && validatePhone(value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    return (
        <div onKeyDown={onKeyDown}>
            <PhoneInput
                international
                labels={es}
                value={value}
                onChange={setValue}
                defaultCountry="CO"
                className={"form-control " + Css.input + " " + Css.phoneInput}
                onBlur={onBlur}/>

            {isValid ? null : <p className={Css.valLabel}>{props.displayName || "Campo"} invalido</p>}
        </div>
    )
}