import Select from "react-select";
import selectStyles from "../common/select-styles";
import Css from "./RoomQuantitySelect.module.css";
import LoadingField from "../loading-field/LoadingField";

export const roomQuantities = [
    {value: 1, label: "1 o más"},
    {value: 2, label: "2 o más"},
    {value: 3, label: "3 o más"},
    {value: 4, label: "4 o más"},
    {value: 5, label: "5 o más"},
];

/**
 * 
 * @param {{
 *  rooms: {value, label} | null
 *  setRooms: Function,
 *  loading: boolean
 * }} props 
 * @returns 
 */
export default function RoomQuantitySelect(props) {
    const onChangeHandler = choice => props.setRooms(choice ? choice : null);
    const classNames = {singleValue: () => Css.roomsMsg};

    if(props.loading) {
        return (<LoadingField />)
    }

    return (
        <Select
            className={"basic-single"}
            classNamePrefix="select"
            isSearchable={false}
            isClearable={true}
            name="Cuartos"
            options={roomQuantities}
            placeholder="Cuartos"
            styles={selectStyles()}
            noOptionsMessage={() => "No hay opciones"}
            onChange={onChangeHandler}
            classNames={classNames}
            defaultValue={props.rooms}
        />
    )
}