/**
 * Decodes HTML special characters. For example
 * decodes "&amp;" into "&". Removes html tags
 * @type {(str: string) => string}
 */
export const decodeHtmlEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');
  
    function decodeHTMLEntities (str) {
      if(str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }
  
      return str;
    }
  
    return decodeHTMLEntities;
  })();

  /**
   * @param {string} str 
   * @returns {string}
   */
  export function capitalizeFirstLetters(str) {
    const strArr = str.split(" ");
    const newStrArr = [];
    for(const substr of strArr) {
      let firstChar = substr[0];
      let rest = substr.slice(1);
      firstChar = firstChar.toUpperCase();
      rest = rest.toLocaleLowerCase();
      newStrArr.push(firstChar + rest);
    }

    return newStrArr.join(" ");
  }