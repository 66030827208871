import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decodeHtmlEntities } from "../../../utils/general-utils";
import Css from "./PropDescription.module.css";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {{property: Object.<string, any>}} props 
 */
export default function PropDescription(props) {
    let description = decodeHtmlEntities(props.property.observations).trim();
    if(!(/\.$/.test(description))) {
        description += ".";
    }
    return (
        <div className={Css.wrapper}>
            <h2>Descripción</h2>
            <p>{description}</p>
        </div>
    )
}