export function getAreaUnitText(areaUnit) {
    return areaUnit.replace(/[0-9]/g, "").toLowerCase();
}

export function getAreaUnitNumber(areaUnit) {
    return areaUnit.replace(/[^0-9]/g, "").toLowerCase();
}

export function getAreaUnitElement(areaUnit) {
    return <>{getAreaUnitText(areaUnit)}<sup>{getAreaUnitNumber(areaUnit)}</sup></>
}