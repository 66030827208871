import { apiResponse2Options } from "../utils/api-utils";
import cacheFetch from "../utils/cache-fetch";

export async function getCities(query_params = {}, cache = {}) {
    query_params.quantity = true;
    let url = "/api/v1/cities";
    let cities = await cacheFetch(url, cache, query_params);
    cities = apiResponse2Options(cities, "id_city", "name");

    return cities;
}