import { useEffect, useRef, useState } from "react";
import Css from "./PropImageSwiper.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player/youtube";

/**
 * @param {string | undefined} video
 * @param {{imgs: string[]}} props 
 * @returns 
 */
export default function PropImageSwiper(props) {
    const swiperElRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const slidesPerView = 1;//window.innerWidth >= 1200 ? 2 : 1;
    const showVideo = Boolean(props.video && props.video !== "");
    const [videoPlaying, setVideoPlaying] = useState(false);
    const videoRef = useRef();

    useEffect(() => {
        prevRef.current.onclick = () => {
            swiperElRef.current.swiper.slidePrev()
            // videoRef.current.getInternalPlayer().pauseVideo();
            setVideoPlaying(false);
        };
        nextRef.current.onclick = () => {
            swiperElRef.current.swiper.slideNext();
            // videoRef.current.getInternalPlayer().pauseVideo();
            setVideoPlaying(false);
        };
    }, []);

    const onPlayHandler = () => {
        setVideoPlaying(true);
    }

    const onPauseHandler = () => {
        setVideoPlaying(false);
    }

    return (
        <div className={Css.swiperWrapper + " shadow-sm"}>
            <swiper-container
                ref={swiperElRef}
                class={Css.swiperContainer}
                loop="true"
                slides-per-view={slidesPerView}
            >
                {showVideo ? (
                    <swiper-slide class={Css.swiperSlide} key={props.video}>
                        <ReactPlayer 
                            url={props.video}
                            ref={videoRef}
                            playing={videoPlaying}
                            onPlay={onPlayHandler}
                            onPause={onPauseHandler}
                            width="100%"
                            height="100%"
                            controls/>
                    </swiper-slide>
                ) : null}
                {props.imgs.map((imgUrl) => {
                    return (
                        <swiper-slide class={Css.swiperSlide} key={imgUrl}>
                            <img className={Css.img} src={imgUrl} alt="Imágen de la propiedad"/>
                        </swiper-slide>
                    )
                })}
            </swiper-container>
            <div className={Css.swiperControlDiv}>
                <div>
                    <div ref={prevRef}>
                        <FontAwesomeIcon icon={faCircleArrowLeft} />
                    </div>
                </div>
                <div>
                    <div ref={nextRef}>
                        <FontAwesomeIcon icon={faCircleArrowRight}/>
                    </div>
                </div>
            </div>
        </div>
    )
}