import Select from "react-select";
import selectStyles from "../common/select-styles";
import { useContext, useEffect, useState } from "react";
import { CacheContext } from "../../../App";
import LoadingField from "../loading-field/LoadingField";
import { getPropTypes } from "../../../services/property-type-sercice";

/**
 * @param {{
 *  propType: {value, label},
 *  loading: boolean,
 *  setPropType: Function,
 * }} props
 */
function PropertyTypeSelect(props) {

    const [propTypes, setPropTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cache = useContext(CacheContext);

    useEffect(() => {
        getPropTypes({}, cache).then(data => {
            setPropTypes(data);
            setIsLoading(false);
        });
    }, []);

    const onChangeHandler = (choice) => {
        if(props.setPropType) {
            if(choice) {
                props.setPropType(choice);
            } else {
                props.setPropType(null);
            }
        }
    };

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <Select
            className={"basic-single " + props.className}
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            name="Tipo inmueble"
            options={propTypes}
            placeholder="Tipo inmueble"
            onChange={onChangeHandler}
            styles={selectStyles()}
            isLoading={isLoading}
            loadingMessage={() => "Cargando..."}
            noOptionsMessage={() => "No hay opciones"}
            defaultValue={props.propType}
        />
    )
}

export default PropertyTypeSelect;