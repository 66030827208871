import { useEffect, useRef, useState } from "react";
import Css from "./PropertyList.module.css";
import { getProperties } from "../../services/property-service";
import PropertyListItem from "./property-list-item/PropertyListItem";
import LoadingPropListItem from "./property-list-item/LoadingPropListItem";
import OrderBySelect, { OrderByDefaultOption } from "./order-by-select/OrderBySelect";
import Pagination from "../pagination/Pagination";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * 
 * @param {{
 *  filters: {
 *      propType: {value, label} | null,
 *      businessType: {value, label} | null,
 *      city: {value, label} | null,
 *      location: {value, label} | null,
 *      zone: {value, label} | null,
 *      propState: {value, label} | null,
 *      rooms: {value, label} | null,
 *      bathrooms: {value, label} | null,
 *      minPrice: {value, label} | null,
 *      maxPrice: {value, label} | null
 * },
 * page: number,
 * take: number
 * }} props 
 */
export default function PropertyList(props) {

    /**
     * @type {[Object.<string, any>[], any]}
     */
    const [properties, _setProperties] = useState([]);
    const orderBy = props.orderBy || OrderByDefaultOption;
    const [loading, setLoading] = useState(true);
    const setTotal = props.setTotal || ((total) => {});
    const take = props.take || 12;
    const page = props.page || 1;
    // const page = useRef(1);
    // const pgsToDisplay = 3;

    const setProperties = async () => {
        if(!loading) {
            setLoading(true);
        }
        const data = await getProperties(props.filters, page, take, orderBy.value.order_by, orderBy.value.order);
        _setProperties(data.properties);
        setTotal(data.total);
        setLoading(false);
    }

    useEffect(() => {
        if(props.filters) {
            setProperties();
        }
    }, [props.filters, orderBy, props.page]);

    const loadingCards = [...Array(take)].map((e,i) => {
        return(
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <LoadingPropListItem />
            </div>
        )
    });


    return (
        <div className={"container " + Css.wrapper}>
            <div className="row">
                {loading ? (loadingCards) : properties.map((property) => {
                    return (
                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={property.id_property}>
                            <PropertyListItem property={property} />
                        </div>
                    )
                })}
            </div>
            {(properties.length === 0 && !loading) ? (
                <div className={Css.noResults}>
                    <div className={Css.exclamation}><FontAwesomeIcon icon={faCircleExclamation} /></div>
                    <p>Oops. Parece que ésta búsqueda no generó ningún resultado.</p>
                    <p>¡Intenta de nuevo ajustando los filtros!</p>
                    <a href="/propiedades/">&times;&nbsp;Eliminar todos los filtros</a>
                </div>
            ) : ""}
        </div>
    )
}