import { apiResponse2Options } from "../utils/api-utils";
import cacheFetch from "../utils/cache-fetch";

/**
 * Gets locations of a given city
 * @param {number} cityId 
 * @param {Object.<string, string>} query_params 
 * @returns {Promise<{value: number, label: string}[]>}
 */
export async function getLocations(cityId, query_params = {}, cache = {}) {
    query_params.quantity = true;
    let url = "/api/v1/cities/" + cityId + "/locations";
    let locations = await cacheFetch(url, cache, query_params);
    locations = apiResponse2Options(locations, "id_location", "name");

    return locations;
}