import Select from "react-select";
import selectStyles from "../common/select-styles";
import { useContext, useEffect, useState } from "react";
import { BUSINESS_TYPE_RENT, BUSINESS_TYPE_SALE } from "../business-type-select/BusinessTypeSelect";
import { CacheContext } from "../../../App";
import LoadingField from "../loading-field/LoadingField";
import { getCities } from "../../../services/city-service";

/**
 * 
 * @param {{
 *  city: {value, label}
 *  propType: {value, label},
 *  businessType: {value, label},
 *  setCity: Function,
 *  loading: boolean
 * }} props 
 * @returns 
 */
function CitySelect(props) {

    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cache = useContext(CacheContext);

    useEffect(() => {
        setIsLoading(true);
        setCities([]);
        let query_params = {quantity: true};
        if(props.propType) {
            query_params.id_property_type = props.propType.value;
        }
        
        if(props.businessType) {
            if(props.businessType.value === BUSINESS_TYPE_RENT) {
                query_params.for_rent = true;
            } else if(props.businessType.value === BUSINESS_TYPE_SALE) {
                query_params.for_sale = true;
            }
        }

        getCities(query_params, cache).then(data => {
            setCities(data);
            setIsLoading(false);
        });


    }, [props.propType, props.businessType]);

    const onChangeHandler = (choice) => {
        if(choice) {
            props.setCity(choice);
        } else {
            props.setCity(null);
        }
    }

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <Select
            className={"basic-single " + props.className}
            classNamePrefix="select"
            isSearchable={true}
            isClearable={true}
            name="Ciudad"
            options={cities}
            placeholder="Ciudad"
            styles={selectStyles()}
            noOptionsMessage={() => "No hay opciones"}
            isLoading={isLoading}
            loadingMessage={() => "Cargando..."}
            onChange={onChangeHandler}
            defaultValue={props.city}
        />
    )
}

export default CitySelect;