import { RENT_TYPES } from "../../../services/rent-type-service";
import Css from "../PropertyDetail.module.css";

export default function PropertyPrice(props) {
    
    const loadingClass = " " + Css.loading + " animated-background";
    const property = props.property;

    if(!property) {
        return (
            <>
                <div className={Css.priceLabel + loadingClass}></div>
                <div className={Css.price + loadingClass}></div>
            </>
        )
    }

    return (
        <>
            <div className={Css.priceLabel}>
                {property.for_sale === "true" ? 
                    "Precio de venta" : 
                    "Precio de alquiler (" + RENT_TYPES[property.id_rents_type] + ")"
                }
            </div>
            <div className={Css.price}>
                <span>
                    {property.for_sale === "true" ? 
                        property.sale_price_label :
                        property.rent_price_label
                    }
                </span>
                <span> {property.iso_currency}</span>
            </div>
        </>
    )
}