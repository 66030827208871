import { Circle, MapContainer, TileLayer } from "react-leaflet";
import Css from "./PropMap.module.css";

/**
 * 
 * @param {{property: Object.<string, any>}} props
 */
export default function PropMap(props) {
    if(props.property && props.property.latitude && props.property.longitude) {
        return (
            <div>
                <h2 className={Css.title}>Mapa</h2>
                <MapContainer center={[props.property.latitude, props.property.longitude]}
                    zoom={16} scrollWheelZoom={false} className={Css.container} style={{zIndex: 0}}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Circle center={[props.property.latitude, props.property.longitude]} 
                        radius={300} color={"#33aacc"} fillColor={"#59ABC2"} fillOpacity={0.3}/>
                       {/* <Circle /> */}
                </MapContainer>
            </div>
        )
    }
}