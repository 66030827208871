import { useEffect, useRef, useState } from "react"
import TestimonialsSlider from "../../components/testimonials/testimonials-slider/TestimonialsSlider"
import "./page-with-sidebar.css"

export default function PageWithSidebar({ children, sideBarContent }) {
    return (
        <div className="content-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="bg-white main-content-div">
                            {children}
                        </div>
                    </div>
                    <div className="mt-4 mt-lg-0 col-12 col-lg-4">
                        <div className="bg-white sidebar-div">
                            {sideBarContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}