import Select from "react-select";
import selectStyles from "../common/select-styles";
import LoadingField from "../loading-field/LoadingField";

export const BUSINESS_TYPE_RENT = "rent";
export const BUSINESS_TYPE_SALE = "sale";
export const businessTypes = [
    {value: BUSINESS_TYPE_SALE, label: "Venta"},
    {value: BUSINESS_TYPE_RENT, label: "Arriendo"},
];

/**
 * @param {{
 *  businessType: {value, label},
 *  setBusinessType: Function,
 *  loading: boolean
 * }} props 
 */
function BusinessTypeSelect(props) {

    const onChangeHandler = (choice) => {
        if(props.setBusinessType) {
            if(choice) {
                props.setBusinessType(choice);
            } else {
                props.setBusinessType(null);
            }
        }
    }

    if(props.loading) {
        return <LoadingField />
    }

    return (
        <Select
            className={props.className}
            name="Tipo negocio"
            options={businessTypes}
            placeholder="Tipo de negocio"
            onChange={onChangeHandler}
            isClearable={true}
            isSearchable={false}
            noOptionsMessage={() => "No hay opciones"}
            styles={selectStyles()}
            defaultValue={props.businessType}
        />
    )

}
export default BusinessTypeSelect;