import Css from "./Error404.module.css";
import errorImg from "../../assets/error404.jpg";
import Error from "./Error";

export default function Error404(props) {
    return (
        <Error title="Error 404 | Inmobiliaria Restrepo Echeverri">
            <div className={Css.wrapper}>
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <img src={errorImg} alt="Error 404" className={Css.errorImg}/>
                    </div>
                    <div className="text-center">
                        <p className="text-size-4 mt-3"><strong>¡Ooops! Parece que ésta página no existe</strong></p>
                        <p className="text-size-2 mb-0">¿Estás buscando una propiedad?</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <a className={Css.button} href="/propiedades/venta/">Inmuebles en Venta</a>
                        <a className={Css.button} href="/propiedades/arriendo/">Inmuebles en Arriendo</a>
                    </div>
                    <hr className="mx-3"/>
                    <p className="text-center">Volver al <a href="/" style={{color: "black"}}>Inicio</a></p>
                </div>
            </div>
        </Error>
    );
}