import { Helmet } from "react-helmet";
import ContactInformation from "../../components/contact-information/ContactInformation";
import Css from "./ContactPage.module.css";
import PageWithSidebar from "../PageWidthSidebar/PageWithSidebar";
import ContactForm from "../../components/contact-form/ContactForm";

export default function ContactPage() {
    return (
        <>
            <Helmet>
                <title>Contáctenos | Inmobiliaria Restrepo Echeverri</title>
                <meta name="description" content="¡Contáctenos! Teléfono: +573228547342 | Whatsapp: +573228547342 | Instagram: @inmobiliariarestrepoecheverri" />
                <link rel="canonical" href="https://restrepoecheverri.com/contactenos/" />
            </Helmet>
            <PageWithSidebar sideBarContent={(
                <ContactInformation />
            )}>
                <h1 className="mt-0">Contáctenos</h1>
                <ContactForm title={"Formulatio de contacto"} />
            </PageWithSidebar>
        </>
    )
}