import { filters2ApiFilters } from "../utils/api-utils";
import { addQueryParams2Url } from "../utils/url-utils";

/**
 * 
 * @param {Object.<string, any>} filters 
 * @param {number} page 
 * @param {number} take 
 * @param {string} orderBy 
 * @param {string} order 
 * @returns {Promise<{total: number, properties: Object<string, any>[]}>}
 */
export async function getProperties(filters = {}, page = 1, take = 10, orderBy = "created_at", order = "asc") {
    const apiFilters = filters2ApiFilters(filters);
    apiFilters.skip = take * (page - 1);
    apiFilters.take = take;
    apiFilters.order_by = orderBy;
    apiFilters.order = order;

    let url = "/api/v1/properties";
    url = addQueryParams2Url(url, apiFilters);
    const response = await fetch(url);
    if(response.status !== 200) {
        throw new Error("Unable to fetch " + url + ". Api responded with code " +
            response.status);
    }

    const data = await response.json();

    const ret = {};
    ret.total = data.total;
    ret.properties = [];

    delete data.total;
    delete data.status;

    for(let key in data) {
        ret.properties.push(data[key]);
    }

    return ret;
}

/**
 * 
 * @param {number} id 
 * @returns {Promise<Object.<string, any>>}
 */
export async function getPropertyById(id) {
    const url = "/api/v1/properties/" + id;
    const response = await fetch(url);
    if(response.status !== 200)  {
        const err = Error("Unable to fetch " + url + ". API responded with code " + response.status);
        if(response.status === 404)
            err.name = "API Error 404";
        else
            err.name = "API Error";
        throw err;
    }

    const data = await response.json();

    return data["0"];
}

export const PROPERTY_STRATUM_LABELS = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "Rural",
    8: "Comercial"
};