import logo from "../../../assets/logos/logo.png";
import Css from "./MainMenu.module.css";
import { Link } from "react-router-dom";
import MenuOpenButton from "../menu-open-button/MenuOpenButton";
import MenuItems from "../menu-items/MenuItems";
import { useRef } from "react";

function MainMenu() {
    /**@type {React.MutableRefObject<HTMLElement>} */
    const menuItemsRef = useRef(null);

    return (
        <div className={Css.divWrapper}>
            <div className="container d-flex justify-content-between">
                <div>
                    <a href="/">
                        <img src={logo} className={Css.logoImg} alt="Logo Inmobiliaria Restrepo Echeverri"/>
                    </a>
                </div>
                <button className={Css.openButton + " justify-content-end"}>
                    <div className="d-flex flex-column justify-content-center h-100">
                        <MenuOpenButton targetRef={menuItemsRef}/>
                    </div>
                </button>
                <MenuItems className="d-none d-lg-flex" />
            </div>
            <MenuItems id="menu1" inContainer={true} className="d-block d-lg-none" ref2={menuItemsRef}/>
        </div>
    )
}

export default MainMenu;