import { useReducer, useRef, useState } from "react";
import Css from "../ContactForm.module.css";

export function TextContactInput(props) {

    const [isValid, setIsValid] = useState(true);
    const inputRef = useRef();
    
    const value = props.value;
    const setValue = props.setValue;

    const onBlur = (e) => {
        validate();
    }

    const onKeyDown = (e) => {
        if(e.key === "Enter") {
            validate()
        }
    }

    const validate = () => {
        if(props.validator) {
            const valid = props.validator(value);
            if(!valid) {
                setIsValid(false);
                return;
            }
        }

        setIsValid(true);
    }

    return (
        <>
            <input
                type="text" 
                className={"form-control " + Css.input} 
                id={props.id} name={props.name}
                placeholder={props.placeholder}
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur} 
                onKeyDown={onKeyDown}
                />

            {isValid ? null : <p className={Css.valLabel}>{props.displayName || "Campo"} invalido</p>}
        </>
    )
}