import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Css from "./Pagination.module.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

/**
 * 
 * @param {{
 *  total: number
 *  take: number
 *  page: React.MutableRefObject<number>
 *  pgsToDisplay: number
 *  setPage: function,
 * }} props 
 */
export default function Pagination(props) {
    // debugger;
    const numPgs = Math.ceil(props.total / props.take);
    let pgsLeft;
    let pgsRight;
    if(numPgs <= props.pgsToDisplay) {
        pgsLeft = props.page - 1;
        pgsRight = props.pgsToDisplay - props.page;
    } else {
        pgsLeft = Math.floor((props.pgsToDisplay - 1) / 2);
        pgsRight = Math.ceil((props.pgsToDisplay - 1) / 2);
        if(props.page - pgsLeft <= 0) {
            pgsRight -= props.page - pgsLeft - 1;
            pgsLeft += props.page - pgsLeft - 1;
        } else if(props.page + pgsRight > numPgs) {
            pgsLeft -= numPgs - (props.page + pgsRight);
            pgsRight += numPgs - (props.page + pgsRight);
        }
    }
    const startPg = props.page - pgsLeft;
    const endPg = props.page + pgsRight;
    // const numPgGroups = Math.ceil(numPgs / props.pgsToDisplay);
    // const currPgGroup = Math.floor((props.page - 1) / props.pgsToDisplay);
    // const currPgPos = (props.page - 1) % props.pgsToDisplay;
    // const firstPage = props.page - currPgPos;
    const pgLst = [];


    const setPage = (page) => () => {
        if(page > numPgs || page < 1) {
            return;
        }

        if(props.setPage) {
            props.setPage(page);
        }
    };

    for(let i = startPg; i <= endPg; i++) {
        pgLst.push(i);
    }


    return (
        <div className={Css.wrapper}>
            <div onClick={setPage(1)}>
                <span class="material-icons">
                    keyboard_double_arrow_left
                </span>
            </div>
            <div onClick={setPage(props.page - 1)}>
                <span class="material-icons">
                    keyboard_arrow_left
                </span>
            </div>
            {/* {currPgGroup !== 0 ? (<><div onClick={setPage(1)}>1</div><div className={Css.dots}>...</div></>) : ""} */}
            {pgLst.map((e, i) => {
                if(!(e > numPgs)) {
                    return (<div key={e} onClick={setPage(e)} className={e === props.page ? Css.selected : ""}>{e}</div>)
                }
            })}
            {/* {(numPgGroups - 1 - currPgGroup) === 0 ? "" : (<div className={Css.dots}>...</div>)} */}
            <div onClick={setPage(props.page + 1)}>
                <span class="material-icons">
                    keyboard_arrow_right
                </span>
            </div>
            <div onClick={setPage(numPgs)}>
                <span class="material-icons">
                    keyboard_double_arrow_right
                </span>
            </div>
        </div>
    )
}