import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessTypeSelect from "../business-type-select/BusinessTypeSelect";
import CitySelect from "../city-select/CitySelect";
import PropertyTypeSelect from "../property-type-select/PropertyTypeSelect";
import Css from "./AdvancedPropSearchForm.module.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import LocationSelect from "../location-select/LocationSelect";
import ZoneSelect from "../zone-select/ZoneSelect";
import PropertyStateSelect from "../property-state-select/PropertyStateSelect";
import RoomQuantitySelect from "../room-quantity-select/RoomQuantitySelect";
import BathroomQuantitySelect from "../bathroom-quantity-select/BathroomQuantitySelect";
import PriceField from "../price-fields/PriceField";

/**
 * @typedef {Object} Option
 * @property {string | number} value
 * @property {string} label
 */

/**
 * @typedef {Object} FilterParams
 * @property {Option | null} propType
 * @property {Option | null} businessType
 * @property {Option | null} city
 * @property {Option | null} location
 * @property {Option | null} zone
 * @property {Option | null} propState
 * @property {Option | null} rooms
 * @property {Option | null} bathrooms
 * @property {Option | null} minPrice
 * @property {Option | null} maxPrice
 */

/**
 * @param {{
 *  defaultValues: {
 *      propType: number | undefined,
 *      businessType: string | undefined,
 *      city: number | undefined,
 *      location: number | undefined,
 *      zone: number | undefined,
 *      propState: number | undefined,
 *      rooms: number | undefined,
 *      bathrooms: number | undefined,
 *      minPrice: number | undefined,
 *      maxPrice: number | undefined
 *  } | null,
 *  onSubmit: (params: FilterParams) => void
 * }} props
 * @returns 
 */
export default function AdvancedPropSearchForm(props) {
    const [propType, setPropType] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [city, setCity] = useState(null);
    const [location, setLocation] = useState(null);
    const [zone, setZone] = useState(null);
    const [propState, setPropState] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [bathrooms, setBathrooms] = useState(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [loading, setLoading] = useState(true);
    
    /**
     * @type {React.MutableRefObject<HTMLElement>}
    */
    const fieldsWrapperRef = useRef();
    const [expandState, _setExpandState] = useState("close");
    const expandRef = useRef("close");

    const setExpandState = (state) => {
        expandRef.current = state;
        _setExpandState(state);
    }

    const showMoreHandler = (e) => {
        e.preventDefault();
        if(expandState === "close") {
            setExpandState("opening");
        } else if(expandState === "open") {
            setExpandState("closing");
        }
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const filterParams = {...{
            propType,
            businessType,
            city,
            location,
            zone,
            propState,
            rooms,
            bathrooms,
            maxPrice,
            minPrice
        }}

        if(props.onSubmit) {
            props.onSubmit(filterParams);
        }
    }

    useEffect(() => {
        const fw = fieldsWrapperRef.current;

        fw.addEventListener("transitionend", () => {
            if(expandRef.current === "opening") {
                setExpandState("open");
            } else if(expandRef.current === "closing") {
                setExpandState("close");
            }
        });
    }, []);

    useEffect(() => {
        if(props.defaultValues) {
            setPropType(props.defaultValues.propType);
            setBusinessType(props.defaultValues.businessType);
            setCity(props.defaultValues.city);
            setLocation(props.defaultValues.location);
            setZone(props.defaultValues.zone);
            setPropState(props.defaultValues.propState);
            setRooms(props.defaultValues.rooms);
            setBathrooms(props.defaultValues.bathrooms);
            setMinPrice(props.defaultValues.minPrice);
            setMaxPrice(props.defaultValues.maxPrice);
            setLoading(false);
        }
    }, [props.defaultValues]);

    
    return (
        <div className={Css.wrapper}>
            <h2 className={"mt-0 " + Css.title}>Encuentra el inmueble de tus sueños</h2>
            <form onSubmit={onSubmitHandler}>
                <div
                    ref={fieldsWrapperRef}
                    id="fieldsWrapper" 
                    className={Css.formRow + " row " +
                        (expandState === "opening" ? Css.opening : "") + 
                        (expandState === "open" ? Css.open : "") +
                        (expandState === "closing" ? Css.closing : "")
                    }
                >
                    <div className="col-12 col-md-4 col-lg-3">
                        <PropertyTypeSelect {...{propType, setPropType, loading}} />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <BusinessTypeSelect {... {businessType, setBusinessType, loading}}/>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <CitySelect {... {city, setCity, propType, businessType, loading}} />
                    </div>
                    <div className={"col-12 col-sm-6 col-md-4 col-lg-3 " + (expandState === "close" ? Css.dNone : "")}>
                        <LocationSelect {... {
                            location,
                            propType,
                            businessType,
                            city,
                            setLocation,
                            loading
                        }}/>
                    </div>
                    <div className={"col-12 col-sm-6 col-md-4 col-lg-3 " + (expandState === "close" ? Css.dNone : "")}>
                        <ZoneSelect {... {
                            zone,
                            propType,
                            businessType,
                            city,
                            location,
                            setZone,
                            loading
                        }}/>
                    </div>
                    <div className={"col-12 col-md-4 col-lg-3 " + (expandState === "close" ? Css.dNone : "")}>
                        <PropertyStateSelect {... {
                            propState,
                            setPropState,
                            loading
                        }}/>
                    </div>
                    <div className={"col-12 col-sm-6 col-lg-3 " + (expandState === "close" ? Css.dNone : "")}>
                        <RoomQuantitySelect {... {rooms, setRooms, loading}}/>
                    </div>
                    <div className={"col-12 col-sm-6 col-lg-3 " + (expandState === "close" ? Css.dNone : "")}>
                        <BathroomQuantitySelect {... {bathrooms, setBathrooms, loading}} />
                    </div>
                    <div className={"col-12 col-sm-6 " + (expandState === "close" ? Css.dNone : "")}>
                        <PriceField
                            price={minPrice}
                            placeholder="Precio mínimo"
                            prefix={<span>Mínimo<br/>(COP)</span>}
                            setPrice={setMinPrice}
                            key={"min-price"}
                            loading={loading}
                        />
                    </div>
                    <div className={"col-12 col-sm-6 " + (expandState === "close" ? Css.dNone : "")}>
                        <PriceField
                            price={maxPrice}
                            placeholder="Precio máximo"
                            prefix={<span>Máximo<br/>(COP)</span>}
                            setPrice={setMaxPrice}
                            key={"max-price"}
                            loading={loading}
                        />
                    </div>
                    
                </div>
                <div className="d-flex justify-content-center">
                    <button className={Css.showMoreLessBtn} onClick={showMoreHandler}>
                        <FontAwesomeIcon 
                            icon={faChevronDown}
                            className={Css.chevron + " " + 
                                (expandState === "open" || expandState === "opening" ? Css.open : "")}/>
                        &nbsp;{(expandState === "open" || expandState === "opening") ? "menos filtros" : "más filtros"}
                    </button>
                </div>
                <div className={Css.submitBtnDiv} >
                    <div className="container d-flex justify-content-end">
                        <input type="submit" value="Buscar" className={Css.submitBtn}/>
                    </div>
                </div>
            </form>
        </div>
    )
}