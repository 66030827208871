import Css from "./PropertyListItem.module.css";

export default function LoadingPropListItem() {
    return (
        <div className={Css.mainDiv + " " + Css.loading}>
            <div className={Css.imageDiv + " animated-background"}>
            </div>
            <div className={Css.body}>
                <div className="animated-background"></div>
                <div className="animated-background"></div>
                <div className="animated-background"></div>
            </div>
        </div>
    )
}